<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <b-card>
    <b-row class="align-items-end mb-2">
      <b-col md="3" xl="3">
        <h6>Cấp học</h6>
        <b-form-group class="mb-0">
          <v-select
              v-model="modelStudentManagementLevelId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :disabled="checkLevel"
              :options="schoolLevel"
              placeholder="Chọn cấp học"
              :reduce="(option) => option.id"
              @input="getListClass"
          />
        </b-form-group>
      </b-col>
      <b-col md="3" xl="3">
        <h6>Khối</h6>
        <b-form-group class="mb-0">
          <v-select
              v-model="modelStudentManagement.classId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="optionClass"
              placeholder="Chọn khối"
              :reduce="(option) => option.id"
              :disabled="checkClassEntity"
          >
            <span slot="no-options">Không có dữ liệu.</span>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="3" xl="3">
        <h6>Trạng thái hồ sơ</h6>
        <b-form-group class="mb-0">
          <v-select v-model="modelStudentManagement.admissionStatus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" placeholder="Chọn trạng thái" :options="admissionStatusOptions"
                        :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="3" ml="3">
        <h6>Lịch sử liên lạc</h6>
        <b-form-group class="mb-0">
          <v-select v-model="modelStudentManagement.status"
                    :options="statusList"
                    :reduce="(option) => option.value"
                    placeholder="Chọn lịch sử"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group label-for="item-dob">
          <h6>Ngày bắt đầu đăng kí hồ sơ</h6>
          <flat-pickr v-model="modelStudentManagement.startDate" @on-change="validateFilter" class="form-control"
                      :config="configDOBFlatpickr" placeholder="Chọn ngày bắt đầu"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <h6>Ngày kết thúc đăng kí hồ sơ</h6>
        <b-form-group label-for="item-dob">
          <flat-pickr v-model="modelStudentManagement.endDate" @on-change="validateFilter" class="form-control"
                      :config="configDOBFlatpickr" placeholder="Chọn ngày kết thúc"
          />
          <small v-if="errorMessage.date1" class="text-danger">{{ errorMessage.date1 }}</small>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <h6>Ngày bắt đầu đăng kí kiểm tra</h6>
        <b-form-group label-for="item-dob">
          <flat-pickr v-model="modelStudentManagement.examinationStartDate" @on-change="validateFilter" class="form-control"
                      :config="configDOBFlatpickr" placeholder="Ngày bắt đầu đăng kí kiểm tra"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <h6>Ngày kết thúc đăng kí kiểm tra</h6>
        <b-form-group label-for="item-dob">
          <flat-pickr v-model="modelStudentManagement.examinationEndDate" @on-change="validateFilter" class="form-control"
                      :config="configDOBFlatpickr" placeholder="Ngày kết thúc đăng kí kiểm tra"
          />
          <small v-if="errorMessage.date2" class="text-danger">{{ errorMessage.date2 }}</small>
        </b-form-group>
      </b-col>
    </b-row> 
    <b-row>
      <b-col md="4" xl="4">
        <h6>Tìm kiếm</h6>
        <b-form-group class="mb-0">
          <input
              v-model="modelStudentManagement.keyWord"
              type="text"
              placeholder="Nhập tên hoặc mã học sinh"
              class="form-control"
              @keyup.enter="getListAllStudent"
          >
        </b-form-group>
      </b-col>
      <b-col md="3">
      <b-form-group>
        <h6>Trạng thái phỏng vấn</h6>
          <v-select id="interviewStatus" v-model="modelStudentManagement.interviewStatus"
                    placeholder="Chọn trạng thái" :options="interviewStatusOptions"
                    :reduce="(option) => option.value">
            <span slot="no-options">Không có dữ liệu.</span></v-select>
        </b-form-group>
      </b-col>
      <b-col md="4" xl="4">
        <div class="mt-2">
          <b-button :disabled="disableSearch"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="filter"
          >
            <feather-icon
                icon="SearchIcon"
                class="mr-50"
            />
            <span class="align-middle">Tìm kiếm</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :to="{ name: 'student-management/add-student' }"
            variant="primary" class="mr-2"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-50"
          />
          <span class="align-middle">Thêm mới</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" class="mr-2" @click="exportFileExcelStudents"
        >
          <feather-icon
              icon="DownloadIcon"
              class="mr-50"
          />
          <span class="align-middle">Xuất danh sách</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" class="mr-2" @click="downloadSampleFile"
        >
          <feather-icon
              icon="DownloadIcon"
              class="mr-50"
          />
          <span class="align-middle">Tải tập tin mẫu</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" @click="$refs.fileUpload.click()"
        >
          <feather-icon
              icon="UploadIcon"
              class="mr-50"
          />
          <span class="align-middle">Thêm tập tin</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" class="ml-2" @click="showModalMultiUpdate" v-if="selected.length > 1"
        >
          <feather-icon
              icon="EditIcon"
              class="mr-50"
          />
          <span class="align-middle">Sửa nhiều</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" class="ml-2" @click="confirmDelete" v-if="selected.length > 1"
        >
          <feather-icon
              icon="TrashIcon"
              class="mr-50"
          />
          <span class="align-middle">Xóa nhiều</span>
        </b-button>
        <input ref="fileUpload" type="file" id="fileUpload" accept=".xlsx, .xls, .csv, .xlsm, .xlsb"
               class="hidden" @change="importFileExcelStudents($event)">

      </b-col>
    </b-row>
    <div class="data-table-link mt-1">
      <div class="row">
        <div class="col-md-12">
          <div class="table-data-employee">
            <span class="float-right mb-2">Có tổng số {{ totalRecordsList }} bản ghi</span>
            <b-table
                responsive
                :items="listStudent"
                :fields="fieldsTable"
                :busy="isBusy"
                :sticky-header="true"
                select-mode="multi"
                ref="selectableTable"
                selectable
                @row-selected="onRowSelected"
                class="mb-0"
            >
            <template #head(selected)>
              <b-form-checkbox v-model="isCheckedAll" @change="selectAllRows"></b-form-checkbox>
            </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(selected)="{ rowSelected }">
               <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                </template>
              </template>
              <template #cell(levelId)="data">
                <label v-if="data.value === 1">
                  Cấp Tiểu học
                </label>
                <label v-if="data.value === 2">
                  Cấp THCS
                </label>
                <label v-if="data.value === 3">
                  Cấp THPT
                </label>
                <label v-if="data.value === 4">
                  Mầm non
                </label>
              </template>
              <template #cell(classEntity)="data">
                <label>
                  {{ (data.value.name) }} - {{ (getInfoClass(data.value.classType)) }}
                </label>
              </template>
              <template #cell(admissionStatus)="data">
                <label
                    v-if="data.value == 0"
                    class="fontSizeLabel fail-exam" style="border-radius: 10px; padding-inline: 1rem;"
                >
                  Chưa thi 
                </label>
                <label
                    v-if="data.value == 2"
                    class="fontSizeLabel fail-exam" style="border-radius: 10px; padding-inline: 1rem;"
                >
                  Chưa trúng tuyển 
                </label>
                <label
                    v-else-if="data.value == 1"
                    class="fontSizeLabel pass-exam" style="border-radius: 10px; padding-inline: 1rem"
                >
                  Đã trúng tuyển 
                </label>
                <!--                <label-->
                <!--                    v-else-if="data.value != 1 && data.value != 0"-->
                <!--                    class="fontSizeLabel"-->
                <!--                >-->
                <!--                  Chưa xác định-->
                <!--                </label>-->
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                    v-b-tooltip.hover.top="'Cập nhật'"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="getDetailStudent(data.item.id)"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button
                    v-b-tooltip.hover.top="'Xoá'"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteStudent(data.item)"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </template>
            </b-table>

            <div class="pagination-custom mt-2 float-right" v-if="totalRecordsList > 0">
              <template>
                <b-pagination
                    v-model="currentPage"
                    :per-page="modelStudentManagement.pageSize"
                    :total-rows="totalRecordsList"
                    first-number
                    last-number
                    @change="onChangePagination"
                />
              </template>
            </div>
            <div class="mt-2 d-flex justify-content-center" v-else>
              <span class="text-secondary">Không có dữ liệu</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="error_import" ref="error_import" size="lg" header-bg-variant="danger"
             header-text-variant="warning" hide-footer title="Lỗi thêm tập tin">
      <b-table selected striped hover :items="errorImportData" :fields="fieldsError"></b-table>
    </b-modal>
    <b-modal id="updateMultiModal" centered no-close-on-backdrop ref="updateMultiModal" size="lg" header-bg-variant="success"
             header-text-variant="warning" hide-footer title="Cập nhật nhiều hồ sơ">
        <h6>Lựa chọn trường cập nhật</h6>
        <b-row class="mt-2">
          <b-col md="12">
            <v-select v-model="editOption" @change="this.editOption = editOption"
             :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" ref="" label="label" placeholder="Chọn trường cần cập nhật" :options="editOptions" :reduce="(option) => option.value">
              <!-- <span slot="no-options">Không có dữ liệu.</span> -->
            </v-select>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'startDate'">
            <b-form-group>
              <h6 class="label-add-student">Ngày tiếp nhận thông tin</h6>
              <flat-pickr v-model="modelStudent.startDate"
                          class="form-control" :config="configDOBFlatpickr"
                          placeholder="Chọn ngày tiếp nhận thông tin"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'status'">
            <b-form-group>
              <v-select v-model="modelStudent.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" placeholder="Chọn trạng thái" :options="statusOptions"
                        :reduce="(option) => option.value">
                <span slot="no-options">Không có dữ liệu.</span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'interviewStatusOptions'">
            <b-form-group>
              <v-select id="interviewStatus" v-model="modelStudent.interviewStatus"
                        placeholder="Chọn trạng thái" :options="interviewStatusOptions"
                        :reduce="(option) => option.value">
                <span slot="no-options">Không có dữ liệu.</span></v-select>
            </b-form-group>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'admissionStatus'">
            <b-form-group>
              <v-select v-model="modelStudent.admissionStatus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" placeholder="Chọn trạng thái" :options="admissionStatusOptions"
                        :reduce="(option) => option.value"
              >
                <span slot="no-options">Không có dữ liệu.</span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'contactTime'">
            <input id="contactTime" v-model="modelStudent.contactTime" type="text" class="form-control"
                               placeholder="Nhập thời gian liên lạc"
                        >
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'boarding'">
            <div class="">
              <b-form-radio-group v-model="modelStudent.boarding" :options="optionBoarding"
                                  name="register-boarding" class="mt-0" value-field="value"
                                  text-field="text"
              />
            </div>
            <div class="mt-2" v-if="modelStudent.boarding === 0">
              <h6>Đăng ký nội trú</h6>
                <v-select v-model="modelStudent.boardingType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="label" placeholder="Đăng ký nội trú" :options="boardingTypeOptions"
                            :reduce="(option) => option.value"
                  >
                  <span slot="no-options">Không có dữ liệu.</span>
              </v-select>
              </div>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'shuttle'">
            <div class="demo-inline-spacing">
              <b-form-radio-group v-model="modelStudent.shuttle" :options="optionShuttle" name="register-shuttle" class="mt-0" value-field="value" text-field="text"
              />
            </div>
            <div class="mt-2" v-if="modelStudent.shuttle != 2">
              <h6>Địa điểm đón</h6>
                  <input id="pickup-location" v-model="modelStudent.pickupLocation" type="text"
                          class="form-control"
                          placeholder="Nhập địa điểm"
                  >
              </div>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'communication'">
            <v-select v-model="modelStudent.communication" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="label" placeholder="Chọn nền tảng" :options="communicationOptions"
                      :reduce="(option) => option.value"
            >
              <span slot="no-options">Không có dữ liệu.</span>
            </v-select>
            <div class="mt-2" v-if="modelStudent.communication == 5">
                <h6>Người giới thiệu</h6>
                <b-form-textarea v-model="modelStudent.communicationDescription" class="form-control" placeholder="Nhập thông tin người giới thiệu" rows="1"/>
              </div>
            <div class="mt-2" v-if="modelStudent.communication == 6">
              <h6>Mô tả</h6>
              <b-form-textarea v-model="modelStudent.communicationDescription" class="form-control" placeholder="Nhập thông tin khác" rows="1"/>
            </div>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'counselorEmployeeName'">
            <input id="counselorEmployeeName" v-model="modelStudent.counselorEmployeeName" type="text" class="form-control" placeholder="Nhập nhân viên tư vấn">
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'note'">
            <b-form-textarea id="textNote" v-model="modelStudent.note" placeholder="Nhập ghi chú" rows="1"/>
          </b-col>
          <b-col class="mt-2" md="12" v-if="editOption == 'studentContactHistoryDTOList'">
            <b-form ref="form" class="repeater-form" @submit.prevent="repeatAgainContactHistory">
              <b-row v-for="(item, index) in modelStudent.studentContactHistoryDTOList" :id="item.id" :key="index" ref="row">
                <b-col md="4">
                  <b-form-group label="Ngày liên hệ" label-for="item-dob">
                    <flat-pickr v-model="item.contactDate" class="form-control" :config="configDOBFlatpickr"
                                placeholder="Chọn ngày"></flat-pickr>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Nội dung" label-for="item-name">
                    <b-form-input v-model="item.description" type="text"
                                  placeholder="Nhập nội dung ..."></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="2" md="2" class="mb-50">
                  <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success"
                            class="btn-icon rounded-circle mt-0 mt-md-2" @click="repeatAgainContactHistory"
                            v-b-tooltip.hover.top="'Thêm bản ghi'"
                            v-if="index + 1 === modelStudent.studentContactHistoryDTOList.length">
                    <feather-icon icon="PlusIcon"/>
                  </b-button>
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger"
                            class="btn-icon rounded-circle mt-0 mt-md-2"
                            @click="removeItemContactHistory(index)"
                            v-if="modelStudent.studentContactHistoryDTOList.length > 1 || !item.id"
                            v-b-tooltip.hover.top="'Xóa bản ghi'">
                    <feather-icon icon="XIcon"/>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2">
          <b-col>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="updateMulti" variant="primary">
              <span class="align-middle">Cập nhật</span>
            </b-button>
          </b-col>
        </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardText, BCol, BFormGroup, BPagination, BRow, BTable, VBTooltip, BModal, BSpinner, BFormCheckbox, BFormRadioGroup, BFormTextarea, BFormInput, BForm
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import {Vietnamese} from 'flatpickr/dist/l10n/vn'
import moment from "moment";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
    BTable,
    BPagination,
    flatPickr,
    BModal,
    BSpinner,
    BFormCheckbox,
    BFormRadioGroup,
    BFormTextarea,
    BFormInput,
    BForm
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      checkLevel: false,
      totalRecordsList: 0,
      records: 0,
      currentPage: 1,
      selected: [],
      allSelected: false,
      isBusy: false,
      errorImportData: [],
      modelStudent: {
        image: {
          base64: null,
          fileName: null,
        },
        id: null,
        contactTime: null,
        relation: null,
        height: null,
        weight: null,
        tuition: null,
        achievements: '',
        address: '',
        bestSubjects: '',
        birthDay: null,
        scholarship: 0,
        boarding: 1,
        boardingType:null,
        classEntity: {
          classType: null,
          name: '',
        },
        promotionsSchools: [],
        studentRegisterProfiles: [],
        currentClass: '',
        district: '',
        email: '',
        familyOfStudentRegister: [
          {
            id: 1,
            schoolName: '',
            birthDay: '',
            employeeCode: '',
            fullName: '',
            gender: null,
            isMisStudent: 1,
          },
        ],
        studentContactHistoryDTOList: [
          {
            id: null,
            contactDate: new Date(),
            description: '',
          },
        ],
        company: '',
        position: '',
        firstName: '',
        fullName: '',
        gender: '',
        gifted: '',
        houseNumber: '',
        lastName: '',
        levelId: null,
        livingWith: [],
        phoneNumber: '',
        presenter: '',
        province: '',
        schoolName: '',
        shuttle: 2,
        startDate: new Date(),
        status: null,
        studentCode: '',
        studyAboard: 1,
        subDistrict: '',
        subjects: '',
        username: '',
        country: '',
        fatherName: '',
        fatherNumber: '',
        fatherEmail: '',
        fatherBirthday: null,
        fatherJob: '',
        fatherJobPosition: '',
        motherName: '',
        motherNumber: '',
        motherEmail: '',
        motherBirthday: null,
        motherJob: '',
        motherJobPosition: '',
        guardianName: '',
        guardianNumber: '',
        guardianEmail: '',
        guardianBirthday: null,
        guardianJob: '',
        guardianJobPosition: '',
        cityAttended: '',
        mathSemester1Score: null,
        mathSemester2Score: null,
        literatureSemester1Score: null,
        literatureSemester2Score: null,
        englishSemester1Score: null,
        englishSemester2Score: null,
        ethnic: null,
        conduct: null,
        grade: null,
        communication: null,
        hobbies: '',
        favoriteDishes: '', //Món ăn yêu thích
        healthIssues: '', //Vấn đề về sức khỏe cần lưu ý
        counselorEmployeeName: '', //Nhân viên tư vấn
        examinationDate: null, //Đăng ký lịch test vào t7 các ngày trong tuần
        relativesDiscountPercentage: 0,
        scholarshipDiscountPercentage: 0,
        siblingsDiscountPercentage: 0,
        communicationDescription:null,
        otherDiscounts: [
          {
            content: "",
            percentage: 0
          },
        ],
        otherDiscountDescription: '',
        totalTuitionAfterDiscount: null,
        entryScoreList: [
          {
            id: null,
            entryDate: new Date(),
            entryEnglishScore: 0,
            entryInterviewScore: '', //Điểm tư duy/phỏng vấn
            entryLiteratureScore: 0,
            entryMathScore: 0,
          }
        ],
        studentEducationNumber: null,
        studentIdentificationNumber: null,
        topEntryEnglishScore: 0,
        topEntryInterviewScore: '',
        topEntryLiteratureScore: 0,
        topEntryMathScore: 0,
      },
      modelStudentManagement: {
        levelId: null,
        classId: 'null',
        status: null,
        pageIndex: 0,
        pageSize: 10,
        keyWord: '',
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
        endDate: new Date(),
        examinationStartDate: null,
        examinationEndDate: null,
        interviewStatus:null,
      },
      fieldsTable: [
        {
          key: 'selected', label: '',
        },
        {
          key: 'studentCode', label: 'Mã HS',
        },
        {
          key: 'fullName', label: 'Tên học sinh',
        },
        {
          key: 'levelId', label: 'Cấp học',
        },
        {
          key: 'classEntity', label: 'Khối',
        },
        {
          key: 'phoneNumber', label: 'Số điện thoại',
        },
        {
          key: 'registerDateStr', label: 'Ngày tạo',
        },
        {
          key: 'admissionStatus', label: 'Trạng thái',
        },
        {
          key: 'hanhdong', label: 'Hành động',
        },
      ],
      allSelected: false,
      listStudent: [],
      schoolLevel: [],
      optionClass: [{name: 'Tất cả', id: 'null'}],
      statusList: [
        {label: 'Chưa liên lạc', value: 0},
        {label: 'Liên lạc lần 1', value: 1},
        {label: 'Liên lạc lần 2', value: 2},
        {label: 'Liên lạc lần 3', value: 3},
        {label: 'Liên lạc lần 4', value: 4},
        {label: 'Liên lạc lần 5', value: 5},
      ],
      interviewStatusOptions: [
        {label: 'Tất cả', value: null},
        {label: 'Không đạt', value: 0},
        {label: 'Đạt', value: 1},
      ],
      admissionStatusOptions: [
        {text: 'Chưa thi', value: 0},
        {text: 'Đã trúng tuyển', value: 1},
        {text: 'Chưa trúng tuyển', value: 2},
      ],
      configDOBFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
      errorImportData: [],
      fieldsError: [
        {
          key: 'stt', label: 'STT',
        },
        {
          key: 'header', label: 'Cột',
        },
        {
          key: 'rowNumError', label: 'Hàng',
        },
        {
          key: 'message', label: 'Lỗi',
        },
      ],
      checkClassEntity: true,
      levelIds: [],
      modelStudentManagementLevelId: null,
      isBusy: false,
      selected: [],
      isCheckedAll:false,
      editOption:null,
      editOptions: [
        {label: 'Lựa chọn', value: null},
        {label: 'Ngày tiếp nhận thông tin', value: 'startDate'},
        {label: 'Trạng thái', value: 'status'},
        {label: 'Trạng thái phỏng vấn', value:'interviewStatus'},
        {label: 'Trạng thái tuyển sinh', value: 'admissionStatus'},
        {label: 'Thời gian liên lạc', value: 'contactTime'},
        {label: 'Đăng kí nội trú', value: 'boarding'},
        {label: 'Đăng ký xe đưa đón', value: 'shuttle'},
        {label: 'Bạn biết chúng tôi qua đâu ?', value: 'communication'},
        {label: 'Nhân viên tư vấn', value: 'counselorEmployeeName'},
        {label: 'Ghi chú', value: 'note'},
        {label: 'Lịch sử liên lạc', value: 'studentContactHistoryDTOList'}
      ],
      statusOptions: [
        {text: 'Đã liên lạc', value: '0'},
        {text: 'Đã ghi danh', value: '1'},
        {text: 'Đã test đầu vào', value: '2'},
        {text: 'Chưa nhập học', value: '3'},
        {text: 'Đã nhập học', value: '4'},
        // {text: 'Tạo trạng thái mới', value: '5'},
        {text: 'Thông báo kết quả', value: '6'},
        {text: 'Đã đặt cọc giữ chỗ', value: '7'},
        {text: 'Thông báo nhập học', value: '8'},
        {text: 'Không trúng tuyển', value: '9'},
      ],
      optionShuttle: [
        {text: 'Tại bến', value: 0},
        {text: 'Tại nhà', value: 1},
        {text: 'Không đăng ký', value: 2},
      ],
      communicationOptions: [
        {label: 'Không', value: null},
        {label: 'Facebook', value: 1},
        {label: 'Google', value: 2},
        {label: 'Mail', value: 3},
        {label: 'Television', value: 4},
        {label: 'Người giới thiệu', value: 5},
        {label: 'Khác', value: 6}
      ],
      optionBoarding: [
        {text: 'Có', value: 0},
        {text: 'Không', value: 1},
      ],
      boardingTypeOptions: [
        {label: 'Mức 1 (3 người)', value: 1},
        {label: 'Mức 2 (8 người)', value: 2},
        {label: 'Mức 3 (10 người)', value: 3}
      ],
      errorMessage: {
        date1: '',  
        date2: ''  
      },
      disableSearch: false
    }
  },
  created() {
    const userCheckRole = JSON.parse(localStorage.getItem('dataMenuUser'))
    userCheckRole.map(x => {
      if (x.level.length > 1) {
        this.levelIds = x.level
        this.modelStudentManagementLevelId = null
        this.checkLevel = false
        this.getListClass()
      }
      if (x.level.length === 1) {
        this.levelIds = x.level
        this.modelStudentManagementLevelId = null
        this.checkLevel = true
        this.getListClass()
      }
    })
    this.getListAllStudent()
    this.getListSchool(userCheckRole)
  },
  methods: {
    validateFilter() {
      if(this.modelStudentManagement.startDate && this.modelStudentManagement.endDate){
        if (new Date(this.modelStudentManagement.startDate).getTime() > new Date(this.modelStudentManagement.endDate).getTime()) { 
          this.errorMessage.date1 = 'Ngày kết thúc đăng kí hồ sơ phải lớn hơn ngày bắt đầu';
          this.disableSearch = true;
        } else {
            this.errorMessage.date1 = '';
            this.disableSearch = false;
        }
      }
      if(this.modelStudentManagement.examinationStartDate && this.modelStudentManagement.examinationEndDate){
        if (new Date(this.modelStudentManagement.examinationStartDate).getTime() > new Date(this.modelStudentManagement.examinationEndDate).getTime()) { 
          this.errorMessage.date2 = 'Ngày kết thúc đăng kí thi phải lớn hơn ngày bắt đầu';
          this.disableSearch = true;
        } else {
            this.errorMessage.date2 = '';
            this.disableSearch = false;
        }
      }
    },
    getListSchool(userCheckRole) {
      this.$crm.get('level-school/all').then(res => {
        this.schoolLevel = res.data
        userCheckRole.map(x => {
          if (x.level.length > 0) {
            let obDTO = []
            res.data.forEach(el => {
              let data = x.level.find(x => x === el.id)
              if (data) obDTO.push(el)
            })
            this.schoolLevel = obDTO
          }
        })
        this.schoolLevel.unshift({name: 'Tất cả', id: 'null'})
      })
    },
    getInfoClass(classType) {
      // eslint-disable-next-line default-case
      switch (classType) {
        case 0:
          return 'STEAM'
        case 1:
          return 'CLC'
        case 2:
          return 'NKNN'
        case 4:
          return 'STEAM A'
        case 5:
          return 'STEAM D'
        case 6:
          return 'CLC A'
        case 7:
          return 'CLC D'
      }
    },
    getListClass() {
      this.optionClass = []
      if (this.modelStudentManagementLevelId > 0 && this.modelStudentManagementLevelId < 4) {
        this.checkClassEntity = false
        this.$crm.get(`class-entity/find-by-level-school-id/${this.modelStudentManagementLevelId}`).then(res => {
          if (res.data && res.data.length > 0) {
            this.optionClass = res.data
            this.optionClass.forEach(item => {
              if (item.classType === 0) {
                item.name = `${item.name} STEAM`
              }
              if (item.classType === 1) {
                item.name = `${item.name} CLC`
              }
              if (item.classType === 2) {
                item.name = `${item.name} NKNN`
              }
              if (item.classType === 4) {
                item.name = `${item.name} Steam A`
              }
              if (item.classType === 5) {
                item.name = `${item.name} Steam D`
              }
              if (item.classType === 6) {
                item.name = `${item.name} CLC A`
              }
              if (item.classType === 7) {
                item.name = `${item.name} CLC D`
              }
            })
            this.optionClass.unshift({name: 'Tất cả', id: 'null'})
          }
        })
      } else {
        this.checkClassEntity = true
        this.modelStudentManagement.classId = 'null'
        this.optionClass.unshift({name: 'Tất cả', id: 'null'})
      }
    },
    getListAllStudent() {
      this.isBusy = true
      this.modelStudentManagement.startDate = this.modelStudentManagement.startDate ? new Date(this.modelStudentManagement.startDate).toISOString() : null
      this.modelStudentManagement.endDate = this.modelStudentManagement.endDate ? new Date(this.modelStudentManagement.endDate).toISOString() : null
      this.modelStudentManagement.examinationStartDate = this.modelStudentManagement.examinationStartDate ? new Date(this.modelStudentManagement.examinationStartDate).toISOString() : null
      this.modelStudentManagement.examinationEndDate = this.modelStudentManagement.examinationEndDate ? new Date(new Date(this.modelStudentManagement.examinationEndDate).setHours(23, 59, 59, 999)).toISOString() : null
      this.modelStudentManagement.interviewStatus = this.modelStudentManagement.interviewStatus 
      this.modelStudentManagement.levelId = JSON.parse(this.modelStudentManagementLevelId) ? ([this.modelStudentManagementLevelId]) : (this.levelIds.length > 0 ? this.levelIds : null)
      this.modelStudentManagement.classId = JSON.parse(this.modelStudentManagement.classId)      
      this.$crm.post('student-register/find-by-condition', this.modelStudentManagement).then(res => {
        this.listStudent = res.data.listData.map(el => {
          el.phoneNumber = el.fatherNumber ? el.fatherNumber : el.motherNumber ? el.motherNumber : el.guardianNumber ? el.guardianNumber : null
          el.registerDateStr = el.registerDate ? moment(el.registerDate).format('DD/MM/YYYY') : null
          return el
        })
        this.totalRecordsList = res.data.totalRecords
        this.records = res.data.records
        setTimeout(() => {
          this.isBusy = false
          if (this.selected.length > 0 && this.listStudent.length > 0) this.checkSelectRow()
        }, 500)
      })
    },
    deleteStudent(item) {
      const fullNameCv = item.fullName.toLocaleUpperCase()
      this.$swal({
        title: 'Xóa hồ sơ',
        text: `Bạn có muốn xóa hồ sơ học sinh  ${fullNameCv}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$crm.post(`student-register/delete/${item.id}`).then(() => {
            this.getListAllStudent()
            this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Thành công!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Xóa thành công hồ sơ học sinh',
                  },
                },
                {timeout: 4000})
          })
        }
      })
    },
    getDetailStudent(item) {
      this.$router.push({
        name: 'student-management/update-student',
        query: {
          id: item,
        },
      })
    },
    onChangePagination(currentPage) {
      this.modelStudentManagement.pageIndex = currentPage - 1
      this.getListAllStudent()
    },
    exportFileExcelStudents() {
      this.$crm.post('student-register/export', this.modelStudentManagement).then(res => {
        this.getFileExcel(res.data.fileName)
      })
    },
    getFileExcel(fileName) {
      if (fileName !== '') {
        this.$crm.get(`file/${fileName}`)
            .then(res => {
              const accesstoken = localStorage.getItem('access_token')
              window.open(`${res.config.baseURL}${res.config.url}?access_token=${accesstoken}`, '_blank')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thành công',
                  icon: 'CheckIcon',
                  text: 'Tải file thành công',
                },
              })
            })
      }
    },

    downloadSampleFile() {
      this.$crm.get('guest/download-import-template').then(res => {
        this.getFileExcel(res.data)
      })
    },

    isExcel(file) {
      return /\.(xlsx)$/.test(file.name)
    },


    importFileExcelStudents(e) {
      let file = [...e.target.files];
      if (!this.isExcel(file[0])) {
        this.$refs.fileUpload.value = null;
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi',
            icon: 'XIcon',
            text: 'Chỉ hỗ trợ tệp excel!',
            variant: 'danger'
          },
        })
      }

      let formData = new FormData();
      formData.append('excelFile', file[0]);

      this.$crm.post('guest/import-excel', formData).then(res => {
        if (res.data && res.data.length > 0) {
          this.errorImportData = res.data.map(el => {
            el.stt = res.data.indexOf(el) + 1;
            return el;
          });
          this.$refs['error_import'].show()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thành công',
              icon: 'CheckIcon',
              text: 'Thêm tập tin thành công!',
              variant: 'success'
            },
          })
          this.getListAllStudent();
        }
      })

      this.$refs.fileUpload.value = null;
    },

    onRowSelected(items) {
      // console.log('items',items)
      this.selected = this.selected.filter(el => !items.includes(el))
      this.listStudent.forEach(el => {
        let result = this.selected.find(x => x.id === el.id)
        if (result) {
          let ind = this.selected.indexOf(result)
          this.selected.splice(ind, 1)
        }
      })
      this.selected.push(...items)

      // Cập nhật trạng thái của checkbox "Check All"
      // const totalCount = this.listStudent.length;
      // const selectedCount = items.length;
      //Cập nhật trạng thái của checkbox "Check All" dựa vào việc selectedCount có bằng totalCount hay không.
      this.isCheckedAll = this.selected.length === this.listStudent.length; 
    },

    filter() {
      this.modelStudentManagement.pageIndex = 0
      this.currentPage = 1
      this.selected = []
      this.getListAllStudent()
    },

    checkSelectRow() {
      this.selected.forEach(el => {
        let result = this.listStudent.find(x => x.id === el.id);
        if (result) {
          this.$refs.selectableTable.selectRow(this.listStudent.indexOf(result))
        }
      })
    },
    selectAllRows() {
      if (this.isCheckedAll) {
        this.$refs.selectableTable.selectAllRows()
      }
      else{
        this.$refs.selectableTable.clearSelected()
      }
    },
    confirmDelete() {
      this.$swal({
        title: 'Xóa hồ sơ',
        text: `Bạn có muốn tất cả xóa hồ sơ học sinh đã chọn không?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          let ids = this.selected.map(el => el.id)
          this.$crm.delete(`/student-register/deletes?ids=${ids}`).then(() => {
            this.selected = []
            this.getListAllStudent()
            this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Thành công!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Xóa thành công hồ sơ học sinh',
                  },
                },
                {timeout: 4000})
          })
        }
      })
    },
    showModalMultiUpdate(){
      this.$refs['updateMultiModal'].show()
    },
    repeatAgainContactHistory() {
      this.modelStudent.studentContactHistoryDTOList.push({
        id: this.nextTodoIdHis += this.nextTodoIdHis,
      })
    },
    removeItemContactHistory(indexHis) {
      if (this.modelStudent.studentContactHistoryDTOList.length > 1) {
        this.modelStudent.studentContactHistoryDTOList.splice(indexHis, 1)
      }
    },
    updateMulti(){ 
      const data = this.selected.map(el => {
        if(this.editOption in el){
          el[this.editOption] = this.modelStudent[this.editOption] 
          el['boardingType'] = this.editOption == 'boarding' ? (el['boarding'] == 0 ? this.modelStudent['boardingType'] : null) : null
          el['pickupLocation'] = this.editOption == 'shuttle' ? (el['shuttle'] != 2 ? this.modelStudent['pickupLocation'] : null) : null
          el['communicationDescription'] = this.editOption == 'communication' ? (el['communication'] == 5 || el['communication'] == 6 ? this.modelStudent['communicationDescription'] : null) : null
        }
        return el;
      })
      // console.log('data', data)
      // return 0;
      this.$crm.post('/student-register/updates', data).then(() => {
        this.selected = []
        this.getListAllStudent()
        this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Sửa nhiều hồ sơ học sinh thành công',
              },
            },
            {timeout: 4000})
      })
      this.$refs['updateMultiModal'].hide()
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select';
@import '../../assets/scss/add-student';
@import '~@core/scss/vue/libs/vue-flatpicker';

.fail-exam {
  background: rgba($danger, .15);
  color: rgba($danger, 1);
}

.pass-exam {
  background: rgba($success, .15);
  color: rgba($success, 1);
}
</style>
